import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { FaPaperPlane, FaComment } from "react-icons/fa"; // Example icon
import { getHitID } from "../../../config/HitIDStorage";

const DEFAULT_IMAGE_URL = `${apiKey}/userProfile/87654321.png`;

const TeacherChat = () => {
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const ID = getHitID();
  const [teacherId, setTeacherId] = useState(null);
  const [messagesByStudent, setMessagesByStudent] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [selectedStudentName, setSelectedStudentName] = useState("");
  const [selectedStudentImage, setSelectedStudentImage] = useState(DEFAULT_IMAGE_URL);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchMessages = () => {
      axios
        .get(`${apiKey}/api/edolutions/admin/showmessage/${ID}`, {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        })
        .then((res) => {
          setTeacherId(res.data.teacher_id);
          setMessagesByStudent(res.data.messages_by_student);

          if (selectedStudentId) {
            const studentMessages = res.data.messages_by_student.find(
              (student) => student.student_id === selectedStudentId
            );
            setSelectedMessages(
              studentMessages
                ? studentMessages.messages.map((msg) => ({
                    ...msg,
                    student_name: studentMessages.student_name,
                  }))
                : []
            );
            setSelectedStudentName(
              studentMessages ? studentMessages.student_name : ""
            );
            setSelectedStudentImage(
              studentMessages
                ? studentMessages.student_photo
                  ? `${apiKey}/studentProfile/${studentMessages.student_photo}`
                  : DEFAULT_IMAGE_URL
                : DEFAULT_IMAGE_URL
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchMessages();
    const intervalId = setInterval(fetchMessages, 3000);

    return () => clearInterval(intervalId);
  }, [selectedStudentId, admin?.token]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedMessages]);

  const handleStudentClick = (studentId) => {
    const studentMessages = messagesByStudent.find(
      (student) => student.student_id === studentId
    );
    setSelectedStudentId(studentId);
    setSelectedMessages(
      studentMessages
        ? studentMessages.messages.map((msg) => ({
            ...msg,
            student_name: studentMessages.student_name,
          }))
        : []
    );
    setSelectedStudentName(studentMessages ? studentMessages.student_name : "");
    setSelectedStudentImage(
      studentMessages
        ? studentMessages.student_photo
          ? `${apiKey}/studentProfile/${studentMessages.student_photo}`
          : DEFAULT_IMAGE_URL
        : DEFAULT_IMAGE_URL
    );
  };

  const [searchQuery, setSearchQuery] = useState("");

  const filteredStudents = messagesByStudent.filter((student) =>
    student.student_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex max-sm:w-[95%] mx-auto relative h-screen bg-gray-50">
      {/* Student List */}
      <div className="w-0 max-sm:hidden max-sm:opacity-0 sm:w-1/4 bg-white border-r border-gray-300 p-4 overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4 text-gray-700">Students</h2>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search students..."
          className="mb-4 w-full border border-gray-300 rounded-lg p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <h6 className="text-sm text-gray-400">Chats</h6>
        <ul>
          {filteredStudents.map((student) => (
            <li
              key={student.student_id}
              onClick={() => handleStudentClick(student.student_id)}
              className={`p-3 mb-3 cursor-pointer rounded-lg transition-colors duration-300 
        ${
          student.student_id === selectedStudentId
            ? "bg-gray-200 text-blue-600"
            : "hover:bg-gray-100"
        } flex items-center gap-3`}
            >
              <img
                src={
                  student.student_photo
                    ? `${apiKey}/studentProfile/${student.student_photo}`
                    : DEFAULT_IMAGE_URL
                }
                onError={(e) => (e.target.src = DEFAULT_IMAGE_URL)} // Set default image on error
                alt={student.student_name}
                className="w-10 h-10 rounded-full object-cover"
              />
              <div className="flex-1">
                <span className="font-semibold">{student.student_name}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="sm:hidden">
    <div className={`absolute top-0 left-0 h-full bg-white border-r border-gray-300 p-4 overflow-y-auto transition-transform duration-300 ${isSidebarOpen ? "w-64 translate-x-0" : "hidden bg-white -translate-x-full"}`}>
      <button 
        className="mb-4 p-2 sm:hidden bg-gray-200 rounded-md flex items-center justify-center w-full" 
        onClick={() => setIsSidebarOpen(false)}
      >
        Close
      </button>
      <h2 className="text-2xl font-bold mb-4 text-gray-700">Students</h2>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search students..."
          className="mb-4 w-full border border-gray-300 rounded-lg p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <h6 className="text-sm text-gray-400">Chats</h6>
        <ul>
          {filteredStudents.map((student) => (
            <li
              key={student.student_id}
              onClick={() => handleStudentClick(student.student_id)}
              className={`p-3 mb-3 cursor-pointer rounded-lg transition-colors duration-300 
        ${
          student.student_id === selectedStudentId
            ? "bg-gray-200 text-blue-600"
            : "hover:bg-gray-100"
        } flex items-center gap-3`}
            >
              <img
                src={
                  student.student_photo
                    ? `${apiKey}/studentProfile/${student.student_photo}`
                    : DEFAULT_IMAGE_URL
                }
                onError={(e) => (e.target.src = DEFAULT_IMAGE_URL)} // Set default image on error
                alt={student.student_name}
                className="w-10 h-10 rounded-full object-cover"
              />
              <div className="flex-1">
                <span className="font-semibold">{student.student_name}</span>
              </div>
            </li>
          ))}
        </ul>
    </div>
    </div>

     
      <button 
      className="absolute sm:hidden top-4 left-1 p-3 bg-gray-200 rounded-md flex items-center justify-center" 
      onClick={() => setIsSidebarOpen(true)}
    >
      <FaComment className="text-gray-700" />
    </button>
      {/* Message Box */}
      <div className="flex-1 flex flex-col bg-gray-200">
        <div className="flex-1 flex flex-col bg-white p-1 shadow-lg overflow-hidden">
          <div className="bg-gray-100 p-4 max-sm:pl-10 rounded-t-lg shadow-sm flex items-center gap-3">
            <img
              src={
                selectedStudentImage ? selectedStudentImage : DEFAULT_IMAGE_URL
              }
              onError={(e) => (e.target.src = DEFAULT_IMAGE_URL)} // Set default image on error
              alt={selectedStudentName}
              className="w-12 h-12 rounded-full object-cover"
            />
            <h2 className="text-2xl font-bold text-gray-700">
              {selectedStudentId
                ? `${selectedStudentName}`
                : "Select a Student"}
            </h2>
          </div>
          <div className="flex-1 overflow-y-auto p-4">
            {selectedMessages.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-500">
                <FaPaperPlane className="text-6xl text-[#4FD1C5] mb-4" />
                <p className="text-[#4FD1C5] max-sm:text-center">
                  No messages yet. Select a student to view the conversation!
                </p>
              </div>
            ) : (
              selectedMessages.map((message) => (
                <div
                  key={message.id}
                  className={`mb-4 p-3 rounded-lg shadow-sm 
                    ${
                      message.teacher_message
                        ? "bg-gray-100 text-black text-left"
                        : "bg-[rgb(187,222,251,0.2)] text-black text-right"
                    }`}
                >
                  <p className="font-semibold">
                    {message.teacher_message
                      ? "Teacher"
                      : `Student (${message.student_name})`}
                    :
                  </p>
                  <p>{message.teacher_message || message.student_message}</p>
                  <p className="text-xs text-gray-500 text-right">
                    {new Date(message.created_at).toLocaleString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false, // Use 24-hour time format
                    })}
                  </p>
                </div>
              ))
            )}
            {/* Scroll reference */}
            <div ref={messagesEndRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherChat;
