import React from "react";
import { NavLink } from "react-router-dom";
import control from "../../../assets/control.png";
import Logo2 from "../../../assets/Edolutions 1.png";
import { MdSearch } from "react-icons/md";
import {
  PiChalkboardTeacherFill,
  PiUserCircleGearFill,
  PiFilesFill,
} from "react-icons/pi";

import { SiGoogleclassroom } from "react-icons/si";
import { FaHome } from "react-icons/fa";


const SideNav = ({ isOpen, toggleSidebar }) => {

  const handleNavClick = () => {
    toggleSidebar();
  };



  return (
    <div
      className={` ${isOpen ? "w-[240px]" : "w-0"
        } bg-white h-screen   absolute xl:relative  duration-300 overflow-y-auto z-10 transition-all border-r-2`}

    >
      <img src={control} alt="" className={`absolute block xl:hidden cursor-pointer -right-3 top-16 sm:top-16 md:top-5 w-7 border-dark-purple border-2 rounded-full ${!isOpen && "rotate-180"}`}
        onClick={toggleSidebar} />


      <div >
        <div className="w-full p-5  md:hidden block">
          <img src={Logo2} alt="" />
        </div>

        <div className={`${isOpen ? "block" : "hidden"} px-5`} >
          <span className="relative md:absolute inset-y-0 left-0 top-8 flex items-center pl-2 ">
            <button className=" flex items-center p-1 focus:outline-none text-[#232738] md:text-black">
              <MdSearch className="sm:hidden block" />
            </button>
          </span>
          <input
            type="text"
            placeholder="Search"
            className="w-full px-4 py-[6px] pl-12 rounded-md shadow cursor-pointer outline-none sm:hidden block border-2"
          />
        </div>
      </div>





      <ul className="mt-3 text-[14px] text-[#232738] font-medium ms-3">
        {/* <li className="mb-2 rounded  text-blue-500 bg-[#BBDEFB1A] py-5">
          <NavLink to="" className="px-3" >
            <MdDashboard className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2 " />
            Dashboard
          </NavLink>
        </li> */}

        <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
          <NavLink to="./profile"   onClick={handleNavClick} className={({ isActive }) => (isActive ? "text-[#4FD1C5] px-3" : "px-3")}>
            <FaHome className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
            Home
          </NavLink>
        </li>

        <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
          <NavLink to="./myTutoring"   onClick={handleNavClick} className={({ isActive }) => (isActive ? "text-[#4FD1C5] px-3" : "px-3")}>
            <SiGoogleclassroom className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
            My Tutoring
          </NavLink>
        </li>
        <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
          <NavLink to="./findTeacher"   onClick={handleNavClick} className={({ isActive }) => (isActive ? "text-[#4FD1C5] px-3" : "px-3")}>
            <PiChalkboardTeacherFill className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
            Find Teacher
          </NavLink>
        </li>

        <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
          <NavLink to="./studentReport"   onClick={handleNavClick} className={({ isActive }) => (isActive ? "text-[#4FD1C5] px-3" : "px-3")}>
            <PiFilesFill className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
            Reports
          </NavLink>
        </li>

        <li className="mb-2 rounded  hover:text-blue-500 active:text-[#4FD1C5] py-5">
          <NavLink to="./proSetting"   onClick={handleNavClick} className={({ isActive }) => (isActive ? "text-[#4FD1C5] px-3" : "px-3")}>
            <PiUserCircleGearFill className="inline-block w-[22.5px] h-[22.5px] mr-2 -mt-2" />
            Settings
          </NavLink>
        </li>
      </ul>


    </div>
  );
};

export default SideNav;
