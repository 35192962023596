import React, { useState, useEffect } from "react";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import apiKey from "../../../config/apiKey"; // Import your apiKey

const DemoClass = () => {
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [tasks, setTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState({ days: "", duration: "" });
  const [editTaskId, setEditTaskId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const closeModal = () => {
    setIsModalOpen(false);
    setEditTaskId(null);
    setCurrentTask({ days: "", duration: "" });
  };
  const [loading, setLoading] = useState(true); // Add loading state
  // Fetch data from the API
  const fetchData = async () => {
    setLoading(true); // Start loading before data fetch
    try {
      const response = await axios.get(`${apiKey}/api/edolutions/admin/demoduration`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      });
      setTasks(response.data);
    } catch (error) {
      toast.error("Failed to fetch data", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false); // Stop loading after data fetch
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${apiKey}/api/edolutions/admin/demoduration`,
        currentTask, {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        }
      );
      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
      fetchData(); // Refresh data
      setCurrentTask({ days: "", duration: "" });
    } catch (error) {
      toast.error("Failed to add duration", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(
        `${apiKey}/api/edolutions/admin/demoduration/${editTaskId}`,
        currentTask, {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        }
      );
      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
      fetchData(); // Refresh data
      closeModal();
    } catch (error) {
      toast.error("Failed to update duration", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${apiKey}/api/edolutions/admin/demoduration/${id}`, {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        }
      );
      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
      fetchData(); // Refresh data
    } catch (error) {
      toast.error("Failed to delete duration", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // Pages Work
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const filteredTasks = tasks.filter(task =>
    task.days.toLowerCase().includes(searchTerm.toLowerCase()) ||
    task.duration.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalItems = filteredTasks.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const paginatedTasks = filteredTasks.slice(startIndex, endIndex);
  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  

  return (
    <div className="relative my-8 flex flex-col min-w-0 break-words  max-sm:max-w-sm  mx-auto w-[90%] sm:w-full  mb-6 shadow-lg bg-white rounded-lg p-8 border-0">
      <div>
        <p className="text-[22px] font-bold">Add Demo Class</p>
      </div>

      <div className="flex flex-wrap gap-2">
        <div className="mb-6 flex justify-start my-5">
          <input
            type="text"
            className="border p-2 mr-2 shadow-md   sm:w-80 rounded-md outline-none"
            placeholder="Enter Demo Days"
            value={currentTask.days}
            onChange={(e) =>
              setCurrentTask({ ...currentTask, days: e.target.value })
            }
          />
        </div>

        <div className="mb-6 flex flex-wrap justify-start my-5">
          <input
            type="text"
            className="border p-2 mr-2 shadow-md   sm:w-80 rounded-md outline-none"
            placeholder="Enter Demo Duration"
            value={currentTask.duration}
            onChange={(e) =>
              setCurrentTask({ ...currentTask, duration: e.target.value })
            }
          />
          {editTaskId ? (
            <button
              className="bg-[#22B7AA] text-md max-sm:mt-2 font-semibold rounded-md text-white px-5 py-2"
              onClick={handleUpdate}
            >
              Update
            </button>
          ) : (
            <button
              className="bg-[#22B7AA] text-md max-sm:mt-2 font-semibold rounded-md text-white px-5 py-2"
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-wrap max-sm:justify-start items-center justify-end">
        <div className="flex ">
          <div className="relative border shadow-md rounded-md flex  items-center px-5 py-2">
            <FiSearch className="h-5 w-5 absolute text-[#5963F5]" />
            <input
              type="text"
              className="outline-none pl-8"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div>
        <table className="w-full mt-5 text-gray-500 border-b table-auto">
          <thead className="text-md text-[#B5B7C0] font-medium border-b">
            <tr className="border-b">
              <th className="py-4 px-1">SR</th>
              <th className="py-4 px-1">Demo Days</th>
              <th className="py-4 px-1">Demo Duration</th>
              <th className="py-4 px-1">Actions</th>
            </tr>
          </thead>
          <tbody className="text-black text-center text-md font-medium">
          {loading ? (
              <tr>
                <td colSpan="9" className="py-5 px-1 text-center">
                  <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                    <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                    <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                      Edolution...
                    </p>
                  </div>
                </td>
              </tr>
            ) : paginatedTasks.length === 0 ? (
              <tr>
                <td colSpan="9" className="py-5 px-1 text-center">
                  <p className="text-gray-500">No demo class available</p>
                </td>
              </tr>
            ) : (
          paginatedTasks.map((task, index) => (
              <tr className="border-b" key={task.id}>
                <td className="py-5 px-1">{startIndex + index + 1}</td>
                <td className="py-5 px-1">{task.days}</td>
                <td className="py-5 px-1">{task.duration}</td>
                <td className="py-5 px-1 flex justify-center gap-8">
                  <button
                    className="text-[#22B7AA] text-[20px]"
                    onClick={() => {
                      setCurrentTask({
                        days: task.days,
                        duration: task.duration,
                      });
                      setEditTaskId(task.id);
                      setIsModalOpen(true);
                    }}
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    className="text-red-500 text-[20px]"
                    onClick={() => handleDelete(task.id)}
                  >
                    <FaRegTrashAlt />
                  </button>
                </td>
              </tr>
            )))}
          </tbody>
        </table>

        <div className="flex flex-wrap gap-5 justify-between items-center w-full text-sm mt-4 pb-4 px-2">
          <div className="flex items-center gap-x-2 text-gray-400">
            <span>
              <select
                className="text-sm border-none text-black bg-[#F5F5F5] rounded-lg"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {[10, 20, 30, 40, 50, 100].map((perPage) => (
                  <option key={perPage} value={perPage}>
                    {perPage}
                  </option>
                ))}
              </select>
            </span>
            <span>Showing Data</span>
            <span>
              {startIndex + 1}-{endIndex} of {totalItems} Entries
            </span>
          </div>
          <div className="flex items-center gap-x-2 text-gray-400">
            <button
              className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft />
            </button>
            <div className="flex items-center gap-x-2 text-gray-400">
              {[...Array(Math.min(5, totalPages || 1))].map((_, index) => {
                const pageNumber = currentPage + index;
                const isActive = pageNumber === currentPage;
                return (
                  <button
                    key={index}
                    className={`text-base rounded-lg px-3 py-1 ${
                      isActive
                        ? "text-white bg-[#4FD1C5]"
                        : "text-black bg-[#F5F5F5]"
                    }`}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={pageNumber < 1 || pageNumber > totalPages}
                  >
                    {pageNumber}
                  </button>
                );
              })}
              <span> of {totalPages || 1} pages</span>
            </div>
            <button
              className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight />
            </button>
          </div>
        </div>
      </div>

      {/* Modal for editing */}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-96">
            <h2 className="text-2xl font-bold mb-4">Update Language</h2>
            <div className="mb-4">
              <label className="block mb-1">Demo Days</label>
              <input
                type="text"
                className="border p-2 w-full"
                value={currentTask.days}
                onChange={(e) =>
                  setCurrentTask({ ...currentTask, days: e.target.value })
                }
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Demo Duration</label>
              <input
                type="text"
                className="border p-2 w-full"
                value={currentTask.duration}
                onChange={(e) =>
                  setCurrentTask({ ...currentTask, duration: e.target.value })
                }
              />
            </div>
            <div className="flex justify-end gap-5">
              <button
                onClick={closeModal}
                className="bg-gray-500 text-sm font-semibold rounded-md text-white px-5 py-2"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdate}
                className="bg-[#22B7AA] text-sm font-semibold rounded-md text-white px-5 py-2"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DemoClass;
