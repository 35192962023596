import React, { useState, useEffect } from 'react';
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { FiSearch } from 'react-icons/fi';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { toast } from "react-toastify";

const 
AcademicSkill = () => {
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState({ id: null, skill_name: "" });
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentTask({ id: null, skill_name: "" });
  };

  // POST WORK
  const [newClassName, setNewClassName] = useState("");
  const [error, setError] = useState("");
  const addClass = () => {
    if (!newClassName.trim()) {
      setError("*Skill is required");
      return;
    }
    axios
      .post(`${apiKey}/api/edolutions/admin/skills`, { skill_name: newClassName }, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
        axios
          .get(`${apiKey}/api/edolutions/admin/skills`, {
            headers: {
              Authorization: `Bearer ${admin?.token}`,
            },
          })
          .then((res) => myGetData(res.data));
        setNewClassName("");
      })
      .catch((error) => {
        console.error("There was an error adding the class!", error);
      });
  };

  // GET WORK
  const [getData, myGetData] = useState([]);
  const [getError, myGetError] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    setLoading(true); // Start loading before data fetch
    axios
      .get(`${apiKey}/api/edolutions/admin/skills`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => myGetData(res.data))
      .catch((error) => myGetError(error.message))
      .finally(() => {
        setLoading(false); // Stop loading after data fetch
      });
  }, [admin?.token]);

  // PUT WORK
  const handleFunctionEdit = (id, skill_name) => {
    setCurrentTask({ id, skill_name });
    setIsModalOpen(true);
  };
  const updateTask = () => {
    axios
      .put(`${apiKey}/api/edolutions/admin/skills/${currentTask.id}`, {
        skill_name: currentTask.skill_name,
      }, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
        axios.get(`${apiKey}/api/edolutions/admin/skills`, {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        }).then((res) => {
          myGetData(res.data);
          closeModal();
        });
      })
      .catch((error) => {
        console.error("There was an error updating the task!", error);
      });
  };

  // DELETE WORK
  const deleteTask = (id) => {
    axios
      .delete(`${apiKey}/api/edolutions/admin/skills/${id}`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((response) => {
        const filteredData = getData.filter((data) => data.id !== id);
        myGetData(filteredData);
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
      })
      .catch((error) => {
        console.error("There was an error deleting the task!", error);
        toast.error("There was an error deleting the task!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  // Pages Work
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const totalItems = getData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  // Search Work
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = getData.filter((item) =>
    item.skill_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  return (
    <div className="relative my-8 max-sm:max-w-xs mx-auto  sm:w-full  flex flex-col min-w-0 break-words  mb-6 shadow-lg bg-white rounded-lg p-8 border-0">
      <div>
        <p className="text-[22px] font-bold">Add Skills</p>
      </div>
      <div className="mb-6 flex max-sm:flex-col max-sm:items-center gap-2 justify-start my-5">
        <input
          type="text"
          className="border p-2 mr-2 shadow-md  max-sm:w-full sm:w-80   rounded-md outline-none"
          placeholder="Enter Your Skills"
          value={newClassName}
          onChange={(e) => setNewClassName(e.target.value)}
        />
        <button
          onClick={addClass}
          className="bg-[#22B7AA] text-md font-semibold rounded-md text-white px-5 py-2"
        >
          Submit
        </button>
      </div>
      {error && <p className="text-red-500">{error}{getError}</p>}

      <div className="flex flex-wrap items-center justify-between">
        <div>
          <p className="text-[22px] font-semibold">Skills</p>
        </div>

        <div className="flex items-center">
          <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
            <FiSearch className="h-5 w-5 absolute text-[#5963F5]" />
            <input
              type="text"
              className="outline-none pl-8"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div>
        <table className="w-full mt-5 text-gray-500 border-b table-auto">
          <thead className="text-md text-[#B5B7C0] font-medium border-b">
            <tr className="border-b">
              <th className="py-4 px-1">SR</th>
              <th className="py-4 px-1">Skill Name</th>
              <th className="py-4 px-1">Actions</th>
            </tr>
          </thead>
          <tbody className="text-black text-center text-md font-medium">
          {loading ? (
              <tr>
                <td colSpan="9" className="py-5 px-1 text-center">
                  <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                    <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                    <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                      Edolution...
                    </p>
                  </div>
                </td>
              </tr>
            ) : filteredData.length === 0 ? (
              <tr>
                <td colSpan="9" className="py-5 px-1 text-center">
                  <p className="text-gray-500">No skills available</p>
                </td>
              </tr>
            ) : (
            filteredData.slice(startIndex, endIndex).map((item, index) => (
              <tr key={item.id} className="border-b">
                <td className="py-5 px-1">{startIndex + index + 1}</td>
                <td className="py-5 px-1">{item.skill_name}</td>
                <td className="py-5 px-1 flex justify-center gap-8">
                  <button
                    onClick={() => handleFunctionEdit(item.id, item.skill_name)}
                    className="text-[#22B7AA] text-[20px]"
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    onClick={() => deleteTask(item.id)}
                    className="text-red-500 text-[20px]"
                  >
                    <FaRegTrashAlt />
                  </button>
                </td>
              </tr>
            )))}
          </tbody>
        </table>

        <div className="flex flex-wrap gap-5 justify-between items-center w-full text-sm mt-4 pb-4 px-2">
          <div className="flex items-center gap-x-2 text-gray-400">
            <span>
              <select
                className="text-sm border-none text-black bg-[#F5F5F5] rounded-lg"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {[10, 20, 30, 40, 50, 100].map((perPage) => (
                  <option key={perPage} value={perPage}>
                    {perPage}
                  </option>
                ))}
              </select>
            </span>
            <span>Showing Data</span>
            <span>
              {startIndex + 1}-{endIndex} of {totalItems} Entries
            </span>
          </div>
          <div className="flex items-center gap-x-2 text-gray-400">
            <button
              className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft />
            </button>
            <div className="flex items-center gap-x-2 text-gray-400">
              {[...Array(Math.min(5, totalPages || 1))].map((_, index) => {
                const pageNumber = currentPage + index;
                const isActive = pageNumber === currentPage;
                return (
                  <button
                    key={index}
                    className={`text-base rounded-lg px-3 py-1 ${
                      isActive
                        ? "text-white bg-[#4FD1C5]"
                        : "text-black bg-[#F5F5F5]"
                    }`}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={pageNumber < 1 || pageNumber > totalPages}
                  >
                    {pageNumber}
                  </button>
                );
              })}
              <span> of {totalPages || 1} pages</span>
            </div>
            <button
              className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight />
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-96">
            <h2 className="text-2xl font-bold mb-4">Update Skill</h2>
            <input
              type="text"
              className="border p-2 w-full rounded-lg shadow-sm mb-4"
              value={currentTask.skill_name}
              onChange={(e) =>
                setCurrentTask({ ...currentTask, skill_name: e.target.value })
              }
            />
            <div className="flex justify-end gap-5">
              <button
                onClick={closeModal}
                className="bg-gray-500 text-sm font-semibold rounded-md text-white px-5 py-2"
              >
                Cancel
              </button>
              <button
                onClick={updateTask}
                className="bg-[#22B7AA] text-sm font-semibold rounded-md text-white px-5 py-2"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AcademicSkill;
