import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import StudentCard from "./StudentCard";
import { FiSearch } from "react-icons/fi";
import { getHitID } from "../../../config/HitIDStorage";
import axios from "axios";
import apiKey from "../../../config/apiKey";

function StudentTution() {
  const ID = getHitID();
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading before data fetch
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/admin/readnotificationsall/${ID}`,
          {
            headers: {
              Authorization: `Bearer ${admin?.token}`,
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setLoading(false); // Stop loading after data fetch
      }
    };
    if (ID && admin?.token) {
      fetchData();
    }
  }, [ID, admin?.token]); // Add admin?.token here
  

  // Filtered data based on search query
  const filteredData = data.filter(item =>
    item.teacher_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.class_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.subjects.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.type_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.day_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="flex  max-sm:max-w-xs mx-auto  overflow-auto   items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <StudentCard />

          <div className="flex flex-wrap max-sm:justify-center md:justify-start px-4 space-x-5 border-b-2 text-base font-medium">
            <NavLink
              to="../studentDash"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Dashboard
            </NavLink>

            <NavLink
              to=".././studentTution"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Tutoring
            </NavLink>

            <NavLink
                to=".././studentChat"
                className={({ isActive }) =>
                  isActive
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : undefined
                }
              >
                Messages
              </NavLink> 
          </div>

          <div className="mt-2 p-4">
            <div className="flex flex-wrap items-center justify-between">
              <div>
                <p className="text-[22px] font-bold">Total Tutoring</p>
              </div>

              <div className="flex flex-wrap gap-2 max-sm:max-w-sm mx-auto items-center">
                <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
                  <FiSearch className="h-5 w-5 absolute text-black" />
                  <input
                    type="text"
                    className="outline-none pl-8"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="mt-5  overflow-auto " >
              <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
                <thead className="text-sm text-[#B5B7C0] font-medium border-b">
                  <tr className="border-b">
                    <td className="py-5 px-1">Teacher Name</td>
                    <td className="py-5 px-1">Class</td>
                    <td className="py-5 px-1">Subject</td>
                    <td className="py-5 px-1">Tutoring Type</td>
                    <td className="py-5 px-1">Day</td>
                    <td className="py-5 px-1">Timing</td>
                    <td className="py-5 px-1">Duration</td>
                    <td className="py-5 px-1">Payment</td>
                  </tr>
                </thead>
                <tbody className="text-black text-sm font-medium">
                {loading ? (
      <tr>
        <td colSpan="8" className="py-5 px-1 text-center">
          <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
            <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
            <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
              Edolution...
            </p>
          </div>
        </td>
      </tr>
    ) : filteredData.length === 0 ? (
      <tr>
        <td colSpan="8" className="py-5 px-1 text-center">
          <p className="text-gray-500">No Teachers Available</p>
        </td>
      </tr>
    ) : (
      filteredData.map((item, index) => (
        <tr key={index} className="border-b">
          <td className="py-5 px-1">{item.teacher_name}</td>
          <td className="py-5 px-1">{item.class_name}</td>
          <td className="py-5 px-1">{item.subjects}</td>
          <td className="py-5 px-1 capitalize">{item.type_name}</td>
          <td className="py-5 px-1 capitalize">
            {item.day_name ? item.day_name : "Not Define"}
          </td>
          <td className="py-5 px-1">
            {item.start_time && item.end_time
              ? `${item.start_time} To ${item.end_time}`
              : item.timeslot_id}
          </td>
          <td className="py-5 px-1">{item.duration}</td>
          <td className="py-5 px-1">Rs.{item.payment}</td>
        </tr>
      ))
    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentTution;
