import React, { useState, useEffect, useCallback } from "react";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { toast } from "react-toastify";
import { setHitID } from "../../config/HitIDStorage";
import axios from "axios";
import apiKey from "../../config/apiKey";
import { FaRegTrashAlt } from "react-icons/fa";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function SrudentTable({ heading, SubHeading }) {
  // id Set
  const handleHitIDReceived = (id) => {
    setHitID(id);
  };

  // Data fetching
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("Newest");
  const admin = JSON.parse(localStorage.getItem("edo-admin"));

  // Update status function
  const updateStatus = async (id) => {
    try {
      const response = await fetch(
        `${apiKey}/api/edolutions/admin/updatestatusstudent/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${admin?.token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        // console.log("Status updated successfully:", data);
        toast.success(data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
        fetchData(); // Refresh data after update
      } else {
        const errorData = await response.json();
        console.error("Error updating status:", errorData);
        toast.error(
          "Error updating status: " + (errorData.message || "Unknown error"),
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Error updating status.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // const fetchData = useCallback(() => {
  //   axios
  //     .get(`${apiKey}/api/edolutions/admin/users/role/3`, {
  //       headers: {
  //         Authorization: `Bearer ${admin?.token}`,
  //       },
  //     })
  //     .then((response) => {
  //       const fetchedData = response.data.map((item) => ({
  //         id: item.id,
  //         column1: item.name,
  //         column2: item.email,
  //         column3: item.phone,
  //         column4: item.status,
  //         column6: item.institute,
  //         column7: item.city,
  //         column8: item.country,
  //         column9: item.address,
  //         column10: item.class_name,
  //         path: ".././studentDash",
  //       }));
  //       setData(fetchedData);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, [admin?.token]);


  const fetchData = useCallback(() => {
    // Start loading before making the request
    setLoading(true);
  
    axios
      .get(`${apiKey}/api/edolutions/admin/users/role/3`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((response) => {
        const fetchedData = response.data.map((item) => ({
          id: item.id,
          column1: item.name,
          column2: item.email,
          column3: item.phone,
          column4: item.status,
          column6: item.institute,
          column7: item.city,
          column8: item.country,
          column9: item.address,
          column10: item.class_name,
          path: ".././studentDash",
        }));
  
        // Set the fetched data
        setData(fetchedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        // Stop loading after the request is complete
        setLoading(false);
      });
  }, [admin?.token]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Search and Sort
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.column1.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.column2.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.column3.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedData = filteredData.sort((a, b) => {
    if (sortOrder === "Newest") {
      return b.id - a.id; // Assuming a higher ID is newer
    } else {
      return a.id - b.id; // Assuming a higher ID is older
    }
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const totalItems = sortedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1); // Reset to first page
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const currentData = sortedData.slice(startIndex, endIndex);

  // Calculate the range of pages to display
  const pageRange = [];
  const maxPageButtons = 5;
  const startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  const endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

  for (let i = startPage; i <= endPage; i++) {
    pageRange.push(i);
  }

  // console.log(currentData);
  const [loading, setLoading] = useState(true); // Add loading state

  // Soft Delete Work
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [softDeleteID, setSoftDeleteID] = useState(null);

  const handleDeleteClick = (id) => {
    setSoftDeleteID(id);
    setIsModalOpen(true);
  };

  const softDelete = async () => {
    try {
      const response = await fetch(
        `${apiKey}/api/edolutions/admin/softdeletestudent/${softDeleteID}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${admin?.token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
        fetchData(); // Refresh data after update
      } else {
        const errorData = await response.json();
        console.error("Error updating visibility:", errorData);
        toast.error(
          "Error updating visibility: " +
            (errorData.message || "Unknown error"),
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      console.error("Error updating visibility:", error);
      toast.error("Error updating visibility.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [expandedRow, setExpandedRow] = useState(null);

  const handleToggleDetails = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <div className="relative max-sm:max-w-xs mx-auto  overflow-auto my-8 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg bg-white rounded-lg p-8 border-0">
      <div className="flex flex-wrap items-center justify-between">
        <div>
          <p className="text-[22px] font-bold">{heading}</p>
          <p className="text-[14px] font-normal text-[#4FD1C5] mt-2">
            {SubHeading}
          </p>
        </div>

        <div className="flex flex-wrap gap-2 max-sm:max-w-sm mx-auto items-center">
          <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
            <FiSearch className="h-5 w-5 absolute text-black" />
            <input
              type="text"
              className="outline-none pl-8"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="relative ml-2">
            <select
              className="border shadow-md rounded-md px-5 py-2 outline-none pl-2 pr-8 text-sm"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <option value="Newest">Sorted By: Newest</option>
              <option value="Oldest">Sorted By: Oldest</option>
            </select>
          </div>
        </div>
      </div>

      <div className="mt-5 overflow-auto ">
        <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
          <thead className="text-sm text-[#B5B7C0] font-semibold border-b">
            <tr className="border-b">
              <td className="py-5 px-1">Student Name</td>
              <td className="py-5 px-1">Email</td>
              <td className="py-5 px-1">Phone</td>
              <td className="py-5 px-1">Class</td>
              <td className="py-5 px-1">Details</td>
              <td className="py-5 px-1">Status</td>
              <td className="py-5 px-1">Delete</td>
            </tr>
          </thead>
          <tbody className="text-black text-sm font-medium">
  {sortedData.length === 0 ? (
    <tr>
      <td colSpan="7" className="py-5 px-1 text-center">
        {loading ? (
          <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
            <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
            <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
              Edolution...
            </p>
          </div>
        ) : (
          <p className="text-gray-500">No data available</p>
        )}
      </td>
    </tr>
  ) : (
    sortedData.slice(startIndex, endIndex).map((item, index) => (
      <React.Fragment key={index}>
        <tr key={item.id} className="border-b">
          <td>
            <Link
              to={item.path}
              onClick={() => handleHitIDReceived(item.id)}
              className="py-5 px-1 capitalize"
            >
              {item.column1}
            </Link>
          </td>
          <td className="py-5 px-1">{item.column2}</td>
          <td className="py-5 px-1">{item.column3}</td>
          <td className="py-5 px-1">{item.column10}</td>
          <td className="py-5 px-1">
            <button
              className="text-blue-500 underline"
              onClick={() => handleToggleDetails(index)}
            >
              {expandedRow === index ? (
                <AiOutlineEyeInvisible className="text-lg text-[#72dad0]" />
              ) : (
                <AiOutlineEye className="text-lg text-[#72dad0]" />
              )}
            </button>
          </td>
          <td className="py-5 px-1 cursor-pointer">
            <p
              className={`border shadow-lg px-5 rounded-md py-1 w-fit ${
                item.column4 === "Inactive"
                  ? "text-red-500"
                  : item.column4 === "Active"
                  ? "text-green-500"
                  : ""
              }`}
              onClick={() => updateStatus(item.id)}
            >
              {item.column4}
            </p>
          </td>
          <td className="py-5 px-1">
            <button
              className="text-red-500 text-[20px]"
              onClick={() => handleDeleteClick(item.id)}
            >
              <FaRegTrashAlt />
            </button>
          </td>
        </tr>

        {expandedRow === index && (
          <tr>
            <td colSpan="7">
              <table className="w-full text-sm text-left text-gray-500 rounded-lg  shadow-lg table-auto">
                <thead className="text-sm text-white  bg-[#72dad0]  font-medium">
                  <tr>
                    <td className="py-3 px-1">Institute</td>
                    <td className="py-3 px-1">City</td>
                    <td className="py-3 px-1">Country</td>
                    <td className="py-3 px-1">Address</td>
                  </tr>
                </thead>
                <tbody className="text-black text-sm font-medium">
                  <tr>
                    <td className="py-5 px-1">{item.column6}</td>
                    <td className="py-5 px-1">{item.column7}</td>
                    <td className="py-5 px-1">{item.column8}</td>
                    <td className="py-5 px-1">{item.column9}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        )}
      </React.Fragment>
    ))
  )}
</tbody>

        </table>

        {/* Pagination */}
        <div className="flex flex-wrap gap-5 justify-between items-center w-full text-sm mt-4 pb-4 px-2">
          <div className="flex items-center gap-x-2 text-gray-400">
            <span>
              <select
                className="text-sm border-none text-black bg-[#F5F5F5] rounded-lg"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {[10, 20, 30, 40, 50, 100].map((perPage) => (
                  <option key={perPage} value={perPage}>
                    {perPage}
                  </option>
                ))}
              </select>
            </span>
            <span>Showing Data</span>
            <span>
              {startIndex + 1}-{endIndex} of {totalItems} Entries
            </span>
          </div>
          <div className="flex items-center gap-x-2 text-gray-400">
            <button
              className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft />
            </button>

            <div className="flex items-center gap-x-2 text-gray-400">
              {pageRange.map((pageNumber) => {
                const isActive = pageNumber === currentPage;
                return (
                  <button
                    key={pageNumber}
                    className={`text-base rounded-lg px-3 py-1 ${
                      isActive
                        ? "text-white bg-[#4FD1C5]"
                        : "text-black bg-[#F5F5F5]"
                    }`}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={pageNumber < 1 || pageNumber > totalPages}
                  >
                    {pageNumber}
                  </button>
                );
              })}
              <span>of {totalPages || 1} pages</span>
            </div>

            <button
              className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight />
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-300 bg-opacity-80">
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              This action will permanently remove the student's data. <br /> Are
              you sure you want to proceed?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => setIsModalOpen(false)}
                className="mr-4 bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  softDelete(softDeleteID);
                  setIsModalOpen(false);
                }}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
